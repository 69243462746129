import axios from 'axios'; // const API_URL = 'http://127.0.0.1:6433';

const API_URL = 'https://www.quannvshen.com';
axios.interceptors.request.use(config => {
  config.headers['Authorization'] = localStorage.getItem('token') || '';
  return config;
}, error => {
  return Promise.reject(error);
});
const http = {
  get(url, data) {
    if (!url.startsWith('http')) {
      url = API_URL + url;
    }

    return axios.get(url, data);
  },

  post(url, data) {
    if (!url.startsWith('http')) {
      url = API_URL + url;
    }

    return axios.post(url, data);
  }

};
export default http;